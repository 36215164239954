import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"

import Layout from "layout/Layout"
import Container from "layout/Container"
import DoctorCompletedMessage from "../components/StaticPages/Completed/DoctorCompletedMessage"
import PatientCompletedMessage from "../components/StaticPages/Completed/PatientCompletedMessage"

export default ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      complete: file(relativePath: { eq: "pages/contactUs__complete.png" }) {
        childImageSharp {
          fluid(maxWidth: 256, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const complete = data.complete.childImageSharp.fluid
  const type = location?.state?.type || ""

  return (
    <Layout seoTitle="Submission Completed">
      <Container isCentered>
        <center>
          <h1 className="title is-size-3 my-2">Thank you!</h1>
          <Container
            isCentered
            mobile={7}
            tablet={4}
            desktop={4}
            customClassName="pb-0"
          >
            <Img fluid={complete} alt="Success!" />
          </Container>
          <p className="pb-1">
            A CarePlus representative will contact you to confirm your
            enrollment. If you don't receive a confirmation email, please
            contact us at{" "}
            <a
              href={`mailto:${process.env.GATSBY_AWS_SES_EMAIL_TO}`}
              className="has-text-weight-bold"
            >
              {process.env.GATSBY_AWS_SES_EMAIL_TO}
            </a>
            .
          </p>
          <DoctorCompletedMessage type={type} />
          <PatientCompletedMessage type={type} />
          <Link to="/" className="button is-primary is-medium">
            Finish
          </Link>
        </center>
      </Container>
    </Layout>
  )
}
